import React from 'react';
import './Button.css';

interface ButtonProps {
  className?: string,
  children: React.ReactNode
}


interface AnchorButtonProps extends ButtonProps {
  href: string
}

export function AnchorButton(props: AnchorButtonProps) {
  return (
    <a className={`button ${props.className}`} href={props.href}>{props.children}</a>
  )
}