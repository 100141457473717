import React, { useContext } from "react";

export type Language = 'en' | 'da' | 'nb' | 'sv';
export const LanguageContext = React.createContext<Language>('en');

export default function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageWrapper(props: {language: Language, children: React.ReactNode}) {
  return (
    <div className={`active-lang-${props.language}`}>
      <LanguageContext.Provider value={props.language}>
        {props.children}
      </LanguageContext.Provider>
    </div>
  )
}

type LocalizedTextInput = {text: string, language: Language};
export function LocalizedText(props: LocalizedTextInput) {
  return (
    <span className={`lang lang-${props.language}`}>
      <span className="text">{props.text}</span>
    </span>
  );
}

type LocalizedTextsInput = {
  texts: LocalizedTextInput[]
}
export function LocalizedTexts(props: LocalizedTextsInput) {
  return (
    <React.Fragment>
      {props.texts.map(p => <LocalizedText key={p.language} {...p} />)}
    </React.Fragment>
  );
}