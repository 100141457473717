import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const root = document.getElementById('root');
const route = root?.getAttribute('data-route');
const model = root?.getAttribute('data-model') && JSON.parse(root?.getAttribute('data-model')!);

ReactDOM.render(
  <React.StrictMode>
    <App route={route} model={model} />
  </React.StrictMode>,
  root
);
