import React from "react";
import { DKNEMID_PREFIX, SEBANKID_PREFIX } from '../constants';

import {LocalizedTexts} from '../../../language';

interface Props {
  acrValue: string
}
export function DKNemIDButtonTexts(props: Props) {
  let suffix = props.acrValue.replace(DKNEMID_PREFIX + ':', '');

  return (
    <React.Fragment>
      <strong>
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'Log in with'},
            {language: 'da', text: 'Log på med'},
            {language: 'sv', text: 'Logga in med'},
            {language: 'nb', text: 'Logg på med'}
          ]}
        />
        &nbsp;
      </strong>
      <strong>NemID</strong><br />
      {suffix === 'poces' ? (
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'Personal'},
            {language: 'da', text: 'Personlig'},
            {language: 'sv', text: 'Personlig'},
            {language: 'nb', text: 'Personlig'}
          ]}
        />
      ) : suffix === 'moces' ? (
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'Employee key card'},
            {language: 'da', text: 'Medarbejder nøglekort'},
            {language: 'sv', text: 'Anställd nyckelkort'},
            {language: 'nb', text: 'Ansatt nøkkelkort'}
          ]}
        />
      ) : suffix === 'moces:codefile' ? (
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'Employee key file'},
            {language: 'da', text: 'Medarbejder nøglefil'},
            {language: 'sv', text: 'Anställd nyckelfil'},
            {language: 'nb', text: 'Ansatt nøkkelfil'}
          ]}
        />
      ) : null}
    </React.Fragment>
  )
}

export function DKMitIDButtonTexts(props: Props) {
  return (
    <React.Fragment>
      <strong>
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'Log in with'},
            {language: 'da', text: 'Log på med'},
            {language: 'sv', text: 'Logga in med'},
            {language: 'nb', text: 'Logg på med'}
          ]}
        />
        &nbsp;
      </strong>
      <strong>MitID</strong>
    </React.Fragment>
  )
}

export function SEBankIDButtonTexts(props: Props) {
  let suffix = props.acrValue.replace(SEBANKID_PREFIX + ':', '');

  return (
    <React.Fragment>
      <strong>BankID</strong><br />
      {suffix === 'same-device' ? (
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'On this device'},
            {language: 'da', text: 'På denne enhed'},
            {language: 'sv', text: 'På denna enheten'},
            {language: 'nb', text: 'På denne enheten'}
          ]}
        />
      ) : suffix === 'another-device' ? (
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'With your SSN'},
            {language: 'da', text: 'Med dit personnummer'},
            {language: 'sv', text: 'Med ditt personnummer'},
            {language: 'nb', text: 'Med personnummeret ditt'}
          ]}
        />
      ) : suffix === 'another-device:qr' ? (
        <LocalizedTexts
          texts={[
            {language: 'en', text: 'With QR Code'},
            {language: 'da', text: 'Med QR-kode'},
            {language: 'sv', text: 'Med QR-kod'},
            {language: 'nb', text: 'Med QR-kode'}
          ]}
        />
      ) : null}
    </React.Fragment>
  )
}
