import React from "react";
import './Frame.css';

interface Props {
  children: React.ReactNode
}

export default function Frame(props: Props) {
  return (
    <main id="frame" className="frame centered">
      {props.children}
    </main>
  );
}