import React from "react";
import useLanguage, { LocalizedTexts } from "../language";

import './Footer.css';

export default function Footer() {
  const language = useLanguage();
  const texts = language === 'en' ? [{language, text: ''}] : [{language, text: ''}, {language: 'en' as typeof language, text: ''}];
  
  return (
    <React.Fragment>
      <aside id="description" className="centered default-hidden desktop-show">
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
        <p className="default-hidden">
          <LocalizedTexts texts={texts} />
        </p>
      </aside>
      <footer id="footer" className="default-hidden desktop-show">
        <div className="inner centered"></div>
      </footer>
    </React.Fragment>
  )
}