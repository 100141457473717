import React from "react";

import './Header.css';
export default function Header(props: {title: string | React.ReactElement}) {
  return (
    <React.Fragment>
      <header id="header" className="default-hidden desktop-show">
        <div className="inner"></div>
      </header>
      <h1 id="headline" className="default-hidden desktop-show centered">
        {props.title}
      </h1>
    </React.Fragment>
  )
}