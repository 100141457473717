import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Frame from '../../components/Frame';
import './AuthMethodSelect.css';
import { AnchorButton } from '../../components/Button';
import {LocalizedTexts} from '../../language';

import { DKNEMID_PREFIX, DKMITID_PREFIX, SEBANKID_PREFIX } from './constants';

import beeid from './logos/beeid.png';
import digid from './logos/digid.png';
import dkmitid from './logos/dkmitid.png';
import dknemid from './logos/dknemid.png';
import ftnmobile from './logos/ftnmobile.png';
import itsme from './logos/itsme.png';
import nobankid from './logos/nobankid.png';
import novipps from './logos/novipps.png';
import sebankid from './logos/sebankid.png';
import sofort from './logos/sofort.png';
import {DKNemIDButtonTexts, DKMitIDButtonTexts, SEBankIDButtonTexts} from './components/ButtonTexts';

export interface AuthMethodSelectModel {
  AcrValues: string[]
}
interface Props {
  model: AuthMethodSelectModel
};

export default function AuthMethodSelect(props: Props) {
  const acrValues = filterAcrValues(props.model.AcrValues ?? []);
  return (
    <React.Fragment>
      <Header
        title={
          <LocalizedTexts
            texts={[
              {language: 'en', text: 'Select login method'},
              {language: 'da', text: 'Vælg login metode'},
              {language: 'sv', text: 'Välj inloggningsmetod'},
              {language: 'nb', text: 'Velg påloggingsmetode'}
            ]}
          />
        }
      />
      <Frame>
        {acrValues.map(acrValue => (
          <AnchorButton key={acrValue} data-test-id={acrValue} href={acrValueToLink(acrValue)} className={`button-eid ${acrValueToClassName(acrValue)}`}>
            {acrValueToLogo(acrValue) ? <img src={acrValueToLogo(acrValue)} alt="" /> : null}
            <AuthMethodText acrValue={acrValue} />
          </AnchorButton>
        ))}
      </Frame>
      <Footer />
    </React.Fragment>
  );
}

function AuthMethodText(props: {acrValue: string}) {
  let title = acrValueToText(props.acrValue);

  return (
    <div className="text">
      {props.acrValue.startsWith(DKNEMID_PREFIX) ?
        <DKNemIDButtonTexts acrValue={props.acrValue} />
      : props.acrValue.startsWith(DKMITID_PREFIX) ?
        <DKMitIDButtonTexts acrValue={props.acrValue} />
      : props.acrValue.startsWith(SEBANKID_PREFIX) ? (
        <SEBankIDButtonTexts acrValue={props.acrValue} />
      ) : (
        <strong>{title}</strong>
      )}
    </div>
  );
}

export function filterAcrValues(input: string[]) {
  let original = input.slice();
  let dkmitidMethod : string | null = null;

  input.forEach(s => {
    if (s.startsWith(DKMITID_PREFIX)) {
      if (!dkmitidMethod) {
        dkmitidMethod = s;
      } else {
        let newLevel = s.replace(DKMITID_PREFIX, '');
        let oldLevel = dkmitidMethod.replace(DKMITID_PREFIX, '');
        if (newLevel === ':low' && [':substantial', ':high'].includes(oldLevel)) {
          dkmitidMethod = s;
        }
        else if (newLevel === ':substantial' && oldLevel === ':high') {
          dkmitidMethod = s;
        }
      }
    }
  });

  if (dkmitidMethod) {
    return input.filter(s => !s.startsWith(DKMITID_PREFIX)).concat([dkmitidMethod]).sort((a, b) => original.indexOf(a) - original.indexOf(b));
  }
  return input;
}

export function acrValueToLink(input: string) {
  const url = new URL(window.location.origin + window.location.pathname + (window.location.search || ''));
  if (url.searchParams.get('acr_values')) {
    url.searchParams.delete('acr_values');
  }
  url.searchParams.append('acr_values', input);
  return url.toString();
}

function autoTitleCase(input: string) {
  var segments = input.split(/:|-/).map(segment => {
    segment = segment.replace(/id(\s|$)/, 'ID');
    if (segment.length === 2) return segment.toUpperCase();
    return segment.substr(0, 1).toUpperCase() + segment.substr(1);
  });
  return segments.join(' ');
}

export function acrValueToText(value: string) {
  value = value.replace('urn:grn:authn:', '');

  if (value.startsWith('be:eid')) {
    return autoTitleCase(value).replace('BEEID', 'Belgian eID');
  }
  if (value.startsWith('nl:digid')) {
    return autoTitleCase(value).replace('NL ', '');
  }
  if (value.startsWith('dk:mitid')) {
    return 'MitID';
  }
  if (value.startsWith('dk:nemid')) {
    return autoTitleCase(value).replace('DK ', '');
  }
  if (value.startsWith('fi')) {
    return autoTitleCase(value).replace('FI', 'FTN');
  }
  if (value.startsWith('itsme')) {
    return autoTitleCase(value).replace('me', 'ME');
  }
  if (value.startsWith('se:bankid')) {
    return autoTitleCase(value).replace('SE ', '');
  }
  if (value.startsWith('de:sofort')) {
    return autoTitleCase(value).replace('DE ', '');
  }
  if (value.startsWith('no:bankid')) {
    return autoTitleCase(value).replace('NO ', '');
  }
  if (value.startsWith('no:vipps')) {
    return autoTitleCase(value).replace('NO ', '');
  }

  return autoTitleCase(value);
}

export function acrValueToClassName(value: string) {
  value = value.replace('urn:grn:authn:', '');
  const segments = value.split(':');
  const classNames = segments.reduce((memo: string[], segment: string) => {
    if (memo.length) {
      return memo.concat([`${memo[memo.length-1]}-${segment}`])
    } else {
      return memo.concat([segment]);
    }
  }, []);

  return classNames.map(className => `button-${className}`).join(' ');
}

export function acrValueToLogo(value : string) {
  if (value.startsWith('urn:grn:authn:be:eid')) {
    return beeid;
  }
  if (value.startsWith('urn:grn:authn:nl:digid')) {
    return digid;
  }
  if (value.startsWith('urn:grn:authn:dk:mitid')) {
    return dkmitid;
  }
  if (value.startsWith('urn:grn:authn:dk:nemid')) {
    return dknemid;
  }
  if (value.startsWith('urn:grn:authn:fi')) {
    return ftnmobile;
  }
  if (value.startsWith('urn:grn:authn:itsme')) {
    return itsme;
  }
  if (value.startsWith('urn:grn:authn:se:bankid')) {
    return sebankid;
  }
  if (value.startsWith('urn:grn:authn:de:sofort')) {
    return sofort;
  }
  if (value.startsWith('urn:grn:authn:no:bankid')) {
    return nobankid;
  }
  if (value.startsWith('urn:grn:authn:no:vipps')) {
    return novipps;
  }
}