import './styling/main.css';
import './styling/utils.css';
import './styling/lang.css';
import './App.css';
import AuthMethodSelect, {AuthMethodSelectModel} from './screens/AuthMethodSelect';
import { Language, LanguageWrapper } from './language';

interface Model {
  Language: Language
}

interface Props {
  route: string | null | undefined,
  model: Model
}

export default function App(props : Props) {
  return (
    <LanguageWrapper language={props.model.Language}>
      {(props.route === 'AuthMethod/Select') ? <AuthMethodSelect model={props.model as any as AuthMethodSelectModel} /> : (
        <div>
          Invalid route
        </div>
      )}
    </LanguageWrapper>
  );
}